<template>
    <div class="col-12 filter">
        <div class="row" v-if="isDesktop">
            <div v-for="(filter,key) of filterItens" :key="key" :class="filtersOptions.columnSizing">
                <span class="filter-label">
                    {{filter.label}}
                </span>
                <VueDatePicker 
                v-if="filter.type == 'datepicker'" 
                    v-model="date" 
                    v-bind="dataPickerOptions"
                    @update:model-value="setFilterDate"
                    uid="-datepicker"/>
                <MultiSelect 
                    v-else
                    :id="filter.id"
                    :list="filter.data" 
                    :placeholder="filter.placeholder" 
                    :label="filter.option" 
                    :multiple="filter.multiple" 
                    :disabled="filter.disabled" 
                    :loading="filter.loading"></MultiSelect>
            </div>
            <div :class="filtersOptions.columnSizing">
                <span class="filter-label">&nbsp;</span>
                <button 
                    class="filter-btn" 
                    @click="retrieveData" 
                    :disabled="!filterButtonStatus">
                    Adicionar
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
    import VueDatePicker from '@vuepic/vue-datepicker';
    import { ptBR, enUS, es } from 'date-fns/locale';
    import '@vuepic/vue-datepicker/dist/main.css'
    import { computed } from 'vue';
    import { DatePicker, MultiSelect } from "@mobs2/styleguide";

    import { useStore } from 'vuex';
    const store = useStore();

    const filtersOptions = computed(() => store.getters.getFilterOptions);
    const filterItens = computed(() => store.getters.getFilterItens);
    const filterButtonStatus = computed(() => store.getters.isFilterButtonActive)

    const isDesktop = computed(() => { return window.matchMedia("(min-width: 1024px)").matches; });    
    
    const setFilterDate = (date) => { store.dispatch('setFilterDate', date); }
    const retrieveData = () => { store.dispatch('setTableData') }

    
const dataPickerOptions = computed(() => {
  
  let options = {
    formatLocale: ptBR,
    clearable: false,
    locale: 'pt',
    position: 'left',
    selectText: 'Selecionar',
    cancelText: 'Cancelar',
    placeholder: 'Selecione uma data',
    hideNavigation: ['time'],
    range: true,
    autoApply: true,
    multiCalendars: true,
    maxDate: new Date()
  };
  return options;
});

</script>