export const filterItensConfig = {
    date: {
        type: 'datepicker',
        id: 'filter-date',
        disabled: false,
        loading: false,
        placeholder: 'Selecione um período',
        option: null,
        label: 'Data',
        range: true,
        multiCalendars:true,
        required: true,
        data: [],
    },
    garages: {
        type: 'multiselect',
        id: 'filter-garage',
        disabled: true,
        loading: false,
        placeholder: 'Selecione a Garagem',
        option: 'name',
        label: 'Garagem',
        value: null,
        multiple: false,
        required: false,
        data: []
    },
    assets: {
        type: 'multiselect',
        id: 'filter-asset',
        disabled: true,
        loading: false,
        placeholder: 'Selecione o Ativo',
        option: 'description',
        label: 'Ativo',
        value: null,
        multiple: false,
        required: false,
        data: []
    },
    drivers: {
        type: 'multiselect',
        id: 'filter-driver',
        disabled: true,
        loading: false,
        placeholder: 'Selecione o Motorista',
        option: 'name',
        label: 'Motorista',
        value: null,
        multiple: false,
        required: false,
        data: []
    },
    routes: {
        type: 'multiselect',
        id: 'filter-route',
        disabled: true,
        loading: false,
        placeholder: 'Selecione a linha',
        option: 'name',
        label: 'Linha',
        value: null,
        multiple: false,
        required: false,
        data: []
    }
}