import { toRaw } from "vue";
import axios from 'axios';
import moment from 'moment';

export const hereMapsStore = {
    state: ()=> ({
        apikey: "ni-Vh7ZV9Y5SIPYhFj01H3B6NT4oKH05kofRQgxq6dU",
        windowH: null,
        herePlatform: null,
        hereMap: null,
        herePositionPolyline: null,
        hereItineraryPolyline: null,
        hereTrackingPoint: null,
        hereStopPointRadius: null,
        hereStopPoints: null,
        hereFullscreen: false,
        checkboxPolyline: true,
        checkboxTrackingPoint: true,
        checkboxStopPointRadius: false,
        checkboxStopPoints: false,
        hereUi: null,
        filters: {
            garage: {
                disabled: true,
                loading: false,
                placeholder: 'Selecione a Garagem',
                option: 'name',
                label: 'Garagem',
                value: null,
                multiple: false,
                data: []
            },
            asset: {
                disabled: true,
                loading: false,
                placeholder: 'Selecione o Ativo',
                option: 'description',
                label: 'Ativo',
                value: null,
                multiple: false,
                data: []
            },
            driver: {
                disabled: true,
                loading: false,
                placeholder: 'Selecione o Motorista',
                option: 'name',
                label: 'Motorista',
                value: null,
                multiple: false,
                data: []
            },
            route: {
                disabled: true,
                loading: false,
                placeholder: 'Selecione a linha',
                option: 'name',
                label: 'Linha',
                value: null,
                multiple: false,
                data: []
            },
        },
        pickedDate: {
            start: null,
            end: null
        },
        filterSite: null,
        filterSiteData: null,
        filterAsset: null,
        filterAssetData: null,
        filterDriver: null,
        filterDriverData: null,
        filterLine: null,
        filterLineData: null,
        tableData: null,
        replayData: [],
        carMarker: null,
        loading: false,
        notification: {
            type: null,
            text: null
        },
        isPaused: false,
        isStopped: false,
        infoBubble: null,
        ui: null,
        H: null,
        playReplay: false,
        replayIndex: 0,
        intervalId: null,
        totalPages: 0,
        s3Id: null,
        replays: [], 
        maxReplays: 5, 
    }),
    mutations: { 
        setMap(state: any, map: any){
            state.hereMap = map;
        },
        setPlatform(state: any, platform: any){
            state.herePlatform = platform;
        },
        setWindowH(state: any, platform: any){
            state.windowH = platform;
        },
        setFullScreen(state: any){
            state.hereFullScreen = !state.hereFullScreen;
        },
        resizeMap(state: any){
            state.hereMap.getViewPort().resize();
        },
        setFilterGarageData(state: any, data: any){
            state.filters.garage.data = data;
        },        
        setFilterAssetData(state: any, data: any){
            state.filters.asset.data = data;
        },
        setFilterDriverData(state: any, data: any){
            state.filters.driver.data = data;
        },
        setFilterRouteData(state: any, data: any){
            state.filters.route.data = data;
        },        
        setPositionPolylineObjectVisibility(state:any, { replayId, visible }:{ replayId:any, visible:any }) {
            const replay = state.replays.find((r:any) => r.id === replayId);
            if (replay && replay.positionPolyline) {
                toRaw(replay.positionPolyline).setVisibility(visible);
            }
        },
        setTrackingPointObjectVisibility(state:any, { replayId, visible }:{ replayId:any, visible:any }) {
            const replay = state.replays.find((r:any) => r.id === replayId);
            if (replay && replay.trackingPoint) {
                toRaw(replay.trackingPoint).setVisibility(visible);
            }
        },
        setStopPointRadiusObjectVisibility(state:any, { replayId, visible }:{ replayId:any, visible:any }) {
            const replay = state.replays.find((r:any) => r.id === replayId);
            if (replay && replay.stopPointRadius) {
                toRaw(replay.stopPointRadius).setVisibility(visible);
            }
        },
        setStopPointsObjectVisibility(state:any, { replayId, visible }:{ replayId:any, visible:any }) {
            const replay = state.replays.find((r:any) => r.id === replayId);
            if (replay && replay.stopPoints) {
                toRaw(replay.stopPoints).setVisibility(visible);
            }
        },

        // Mutações para gerenciar múltiplos replays
        addReplay(state:any, replay:any) {
            if (state.replays.length >= state.maxReplays) {
                // Remover o replay mais antigo
                const oldestReplay = state.replays.shift();
                // Remover objetos do mapa relacionados ao replay mais antigo
                if (oldestReplay.carMarker) state.hereMap.removeObject(oldestReplay.carMarker);
                if (oldestReplay.positionPolyline) state.hereMap.removeObject(oldestReplay.positionPolyline);
                if (oldestReplay.itineraryPolyline) state.hereMap.removeObject(oldestReplay.itineraryPolyline);
                if (oldestReplay.trackingPoint) state.hereMap.removeObject(oldestReplay.trackingPoint);
                if (oldestReplay.stopPointRadius) state.hereMap.removeObject(oldestReplay.stopPointRadius);
                if (oldestReplay.stopPoints) state.hereMap.removeObject(oldestReplay.stopPoints);
                if (oldestReplay.infoBubble) state.hereUi.removeBubble(oldestReplay.infoBubble);
            }
            state.replays.push(replay);
        },
        removeReplay(state:any, replayId:any) {
            const replayIndex = replayId-1;
            if (replayIndex !== -1) {
                const replay = state.replays[replayIndex];
                // Remover objetos do mapa
                if (replay.carMarker) state.hereMap.removeObject(replay.carMarker);
                if (replay.positionPolyline) state.hereMap.removeObject(replay.positionPolyline);
                if (replay.itineraryPolyline) state.hereMap.removeObject(replay.itineraryPolyline);
                if (replay.trackingPoint) state.hereMap.removeObject(replay.trackingPoint);
                if (replay.stopPointRadius) state.hereMap.removeObject(replay.stopPointRadius);
                if (replay.stopPoints) state.hereMap.removeObject(replay.stopPoints);
                if (replay.infoBubble) state.hereUi.removeBubble(replay.infoBubble);
                // Remover do array
                state.replays.splice(replayIndex, 1);
            }
        },
        setTableData(state: any, data:any){
            state.tableData = data;
        },
        toggleLoading(state: any){
            state.loading = !state.loading
        },
        setPickedDate(state: any, data: any){
            state.pickedDate = data
        },
        toggleFiltersLoading(state: any){
            state.filters.asset.loading = !state.filters.asset.loading;
            state.filters.driver.loading = !state.filters.driver.loading;
            state.filters.route.loading = !state.filters.route.loading;
            state.filters.garage.loading = !state.filters.garage.loading;
        },
        toggleFilterGarageLoadingDisabled(state: any, data: any){
            if(data && state.filters.garage.disabled){
                state.filters.garage.disabled = !state.filters.garage.disabled;
            }
            state.filters.garage.loading = !state.filters.garage.loading;
        },
        toggleFilterAssetLoadingDisabled(state: any, data: any){
            if(data && state.filters.asset.disabled){
                state.filters.asset.disabled = !state.filters.asset.disabled;
            }
            state.filters.asset.loading = !state.filters.asset.loading;
        },
        toggleFilterDriverLoadingDisabled(state: any, data: any){
            if(data && state.filters.driver.disabled){
                state.filters.driver.disabled = !state.filters.driver.disabled;
            }
            state.filters.driver.loading = !state.filters.driver.loading;
        },
        toggleFilterRouteLoadingDisabled(state: any, data: any){
            if(data && state.filters.route.disabled){
                state.filters.route.disabled = !state.filters.route.disabled;
            }
            state.filters.route.loading = !state.filters.route.loading;
        },
        doNotify(state: any, data: any){            
            state.notification = data;
            setTimeout(() => {
                state.notification = {
                    type: null,
                    text: null,
                };
            }, 3000);
        },
        playReplay(state: any){
            state.playReplay = true;
        },
        pauseReplay(state: any){
            state.playReplay = false;
        },
        stopReplay(state: any){
            state.playReplay = false;
            state.replayIndex = 0;
        },
     },
    actions: { 
        playReplay({commit, state}: { commit: any, state: any}){
            // Se estiver pausado, apenas continua o replay
            if (state.isPaused) {
                state.isPaused = false;
                state.isStopped = false;
                startReplay();
                return;
            }

            commit('playReplay');
            state.isStopped = false; // Certifique-se de que o replay não está parado

            function animateMarker(timestamp: number, startTimestamp: number, fromLatLng: { lat: number, lng: number }, toLatLng: { lat: number, lng: number }, duration: number) {
                // Se o replay foi parado, interrompa a animação
                if (state.isStopped) return;

                // Se o replay estiver pausado, não continua a animação
                if (state.isPaused) return;

                // Calcula o progresso baseado no tempo passado
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);

                // Interpola entre as coordenadas de início e fim
                const currentLat = fromLatLng.lat + (toLatLng.lat - fromLatLng.lat) * progress;
                const currentLng = fromLatLng.lng + (toLatLng.lng - fromLatLng.lng) * progress;

                // Atualiza a posição do marcador
                state.replays[0].carMarker.setGeometry({
                    lat: currentLat,
                    lng: currentLng,
                });

                // Atualiza o conteúdo da InfoBubble dinamicamente
                if (state.replays[0].infoBubble) {
                    let xy = state.hereMap.geoToScreen({
                        lat: state.replays[0].data[state.replayIndex].lat, 
                        lng:  state.replays[0].data[state.replayIndex].lng})
                    state.replays[0].infoBubble.setPosition(state.hereMap.screenToGeo(xy.x, xy.y - 65) ); state.replays[0].infoBubble.setContent(`
                            <div class="here-infoBubble">
                                <div class="header-infoBubble">
                                    ${state.replays[0].data[state.replayIndex].assetDescription}
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Data
                                        </strong>
                                        <span class="info-bubble">
                                        ${moment(state.replays[0].data[state.replayIndex].timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                        </span>
                                    </div>
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Velocidade
                                        </strong>
                                        <span class="info-bubble">
                                        ${state.replays[0].data[state.replayIndex].speed} km/h
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Hodômetro
                                        </strong>
                                        <span class="info-bubble">
                                        ${state.replays[0].data[state.replayIndex].odometer}
                                        </span>
                                    </div>
                                </div>                                    
                                <div class="row">
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Motorista
                                        </strong>
                                        <span class="info-bubble">
                                        ${state.replays[0].data[state.replayIndex].driverName}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="badge-bubble ${(state.replays[0].data[state.replayIndex].ignition ? 'success' : 'error')}">
                                        ${(state.replays[0].data[state.replayIndex].ignition ? 'Ligado' : 'Desligado')}
                                        </span>
                                    </div>
                                </div>
                            </div>`
                    );
                }

                // Continua a animação se o progresso não atingiu 100%
                if (progress < 1) {
                    requestAnimationFrame((newTimestamp) => animateMarker(newTimestamp, startTimestamp, fromLatLng, toLatLng, duration));
                } else {
                    // Após a animação, continua para a próxima posição
                    if (state.replayIndex < state.replays[0].data.length - 1) {
                        state.replayIndex += 1;
                        startReplay();
                    } else {
                        commit('stopReplay');
                    }
                }
            }

            function startReplay() {
                if (state.replayIndex < state.replays[0].data.length - 1) {
                    const currentPosition = state.replays[0].data[state.replayIndex];
                    const nextPosition = state.replays[0].data[state.replayIndex + 1];

                    // Inicia a animação do marcador com `requestAnimationFrame`
                    requestAnimationFrame((timestamp) => {
                        animateMarker(timestamp, timestamp, { lat: currentPosition.lat, lng: currentPosition.lng }, { lat: nextPosition.lat, lng: nextPosition.lng }, 1000);
                    });
                }
            }

            // Inicializa a InfoBubble apenas na primeira execução
            if (!state.replays[0].infoBubble) {
                let xy = state.hereMap.geoToScreen({
                    lat: state.replays[0].data[state.replayIndex].lat, 
                    lng:  state.replays[0].data[state.replayIndex].lng})
                state.replays[0].infoBubble = new state.H.ui.InfoBubble(state.hereMap.screenToGeo(xy.x, xy.y - 65) ); state.replays[0].infoBubble.setContent(`
                        <div class="here-infoBubble">
                            <div class="header-infoBubble">
                                ${state.replays[0].data[state.replayIndex].assetDescription}
                            </div>
                            <div class="row">
                                <div class="col">
                                    <strong class="subheader-bubble">
                                        Data
                                    </strong>
                                    <span class="info-bubble">
                                    ${moment(state.replays[0].data[state.replayIndex].timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                    </span>
                                </div>
                                <div class="col">
                                    <strong class="subheader-bubble">
                                        Velocidade
                                    </strong>
                                    <span class="info-bubble">
                                    ${state.replays[0].data[state.replayIndex].speed} km/h
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <strong class="subheader-bubble">
                                        Hodômetro
                                    </strong>
                                    <span class="info-bubble">
                                    ${state.replays[0].data[state.replayIndex].odometer}
                                    </span>
                                </div>
                            </div>                                    
                            <div class="row">
                                <div class="col">
                                    <strong class="subheader-bubble">
                                        Motorista
                                    </strong>
                                    <span class="info-bubble">
                                    ${state.replays[0].data[state.replayIndex].driverName}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span class="badge-bubble ${(state.replays[0].data[state.replayIndex].ignition ? 'success' : 'error')}">
                                    ${(state.replays[0].data[state.replayIndex].ignition ? 'Ligado' : 'Desligado')}
                                    </span>
                                </div>
                            </div>
                        </div>`
                );

                // Adiciona a InfoBubble ao UI do mapa
                state.ui.addBubble(state.replays[0].infoBubble);

                // Adiciona um evento de clique ao marcador para exibir a InfoBubble
                state.replays[0].carMarker.addEventListener('tap', () => {
                    state.replays[0].infoBubble.open();
                });
            }

            startReplay();
        },
        pauseReplay({commit, state}: { commit: any, state: any}){
            state.isPaused = true; // Marca o replay como pausado
            state.playReplay = false; // Marca o replay como pausado
        },
        stopReplay({commit, state}: { commit: any, state: any}){
            state.isPaused = false;
            state.isStopped = true; // Marca o replay como parado
            state.playReplay = false; // Marca o replay como pausado
            state.replayIndex = 0; // Reinicia o índice do replay
            commit('stopReplay');

            // Reseta o marcador e o mapa para a posição inicial
            if (state.replays[0].data.length > 0) {
                const initialPosition = state.replays[0].data[0];
                state.replays[0].carMarker.setGeometry({
                    lat: initialPosition.lat,
                    lng: initialPosition.lng,
                });

                // Reseta o conteúdo da InfoBubble
                if (state.replays[0].infoBubble) {
                    let xy = state.hereMap.geoToScreen({
                        lat: initialPosition.lat, 
                        lng:  initialPosition.lng})
                    state.replays[0].infoBubble.setPosition(state.hereMap.screenToGeo(xy.x, xy.y - 65) ); state.replays[0].infoBubble.setContent(`
                            <div class="here-infoBubble">
                                <div class="header-infoBubble">
                                    ${state.replays[0].data[state.replayIndex].assetDescription}
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Data
                                        </strong>
                                        <span class="info-bubble">
                                        ${moment(state.replays[0].data[state.replayIndex].timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                        </span>
                                    </div>
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Velocidade
                                        </strong>
                                        <span class="info-bubble">
                                        ${state.replays[0].data[state.replayIndex].speed} km/h
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Hodômetro
                                        </strong>
                                        <span class="info-bubble">
                                        ${state.replays[0].data[state.replayIndex].odometer}
                                        </span>
                                    </div>
                                </div>                                    
                                <div class="row">
                                    <div class="col">
                                        <strong class="subheader-bubble">
                                            Motorista
                                        </strong>
                                        <span class="info-bubble">
                                        ${state.replays[0].data[state.replayIndex].driverName}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="badge-bubble ${(state.replays[0].data[state.replayIndex].ignition ? 'success' : 'error')}">
                                        ${(state.replays[0].data[state.replayIndex].ignition ? 'Ligado' : 'Desligado')}
                                        </span>
                                    </div>
                                </div>
                            </div>`
                    );
                }
            }
        },
        doNotify({commit}: { commit: any}, data:any){
            commit('doNotify', data)
        },
        setPickedDate({commit}: { commit: any}, {data} : {data:any}){
            commit('setPickedDate', {
                start: moment(data[0]).format('YYYY-MM-DD'),
                end: moment(data[1]).format('YYYY-MM-DD')
            })
        },
        setCheckboxPolyline({commit}: { commit: any }, data:any){
            commit('setPositionPolylineObjectVisibility', data)
        },
        setCheckboxTrackingPoint({commit}: { commit: any }, data:any){
            commit('setTrackingPointObjectVisibility', data)
        },
        setCheckboxStopPointRadius({commit}: { commit: any }, data:any){
            commit('setStopPointRadiusObjectVisibility', data)
        },
        setCheckboxStopPoints({commit}: { commit: any }, data:any){
            commit('setStopPointsObjectVisibility', data)
        },
        setToggleLoading({commit}: { commit: any }){
            commit('toggleLoading');
        },
        setFilterSiteData({commit, state}: { commit: any; state: any }, {data} : {data:any}){
            commit('setFilterSiteData', data)
        },
        setFilterAssetData({commit, state}: { commit: any; state: any }, {data} : {data:any}){
            commit('setFilterAssetData', data)
        },
        setFilterDriverData({commit, state}: { commit: any; state: any }, {data} : {data:any}){
            commit('setFilterDriverData', data)
        },
        setFilterLineData({commit, state}: { commit: any; state: any }, {data} : {data:any}){
            commit('setFilterLineData', data)
        },
        async setFilters({commit, state}: { commit: any; state: any }){
            commit('toggleFiltersLoading');
            await axios.get('https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/garages',
                {headers: {organization: sessionStorage.getItem('orgid')}}).then(response => {
                    commit('setFilterGarageData', response.data.data.garages);
                    commit('toggleFilterGarageLoadingDisabled', response.data.data.garages.length > 0);
            });
        
            await axios.get('https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/assets',
                {headers: {organization: sessionStorage.getItem('orgid')}}).then(response => {
                    commit('setFilterAssetData', response.data.data.assets);
                    commit('toggleFilterAssetLoadingDisabled', response.data.data.assets.length > 0);
            });
        
            await axios.get('https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/drivers',
                {headers: {organization: sessionStorage.getItem('orgid')}}).then(response => {
                    commit('setFilterDriverData', response.data.data.drivers);
                    commit('toggleFilterDriverLoadingDisabled', response.data.data.drivers.length > 0);
            });
        
            await axios.get('https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/routes',
                {headers: {organization: sessionStorage.getItem('orgid')}}).then(response => {
                    commit('setFilterRouteData', response.data.data.routes)
                    commit('toggleFilterRouteLoadingDisabled', response.data.data.routes.length > 0);
            });
        },
        async defineTableData({commit, state}: { commit: any; state: any }, data: any){
            commit('setTableData', data)
        },
        async setTableData2({commit, state}: { commit: any; state: any }){
            commit('toggleLoading');
            await axios.get('https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/operational-registers-report/paginated/',
            {
                headers: {
                    organization: sessionStorage.getItem('orgid')
                },
                params: {
                    startdate: state.pickedDate.start,
                    enddate: state.pickedDate.end,
                    assetid:  state.filters.asset.value,
                    driverid: state.filters.asset.value,
                    garageid: state.filters.asset.value,
                    routeid: state.filters.asset.value,
                }
            }).then(response => {
                commit('setTableData', response.data.data.firstPage)
                state.s3Id =  response.data.data.id
                state.totalPages =  response.data.data.totalPages
            }).catch(response => {
                commit('doNotify', {text: "Nenhum dado foi encontrado. Ajuste sua pesquisa e tente novamente", type: "warning"});
            });
            commit('toggleLoading');
        },
        async setReplayData({ commit, state }:{ commit:any, state:any }, { data, H, icon01, icon02, icon03 }: { data:any, H:any, icon01:any, icon02:any, icon03:any }) {
            
            // Gerar um ID único para o replay
            const replayId = `replay_${Date.now()}`;

            // Criar um novo objeto de replay
            const newReplay = {
                id: replayId,
                data: [], // Dados do replay
                carMarker: null,
                positionPolyline: null,
                itineraryPolyline: null,
                trackingPoint: null,
                stopPointRadius: null,
                stopPoints: null,
                infoBubble: null,
                replayIndex: 0,
            };

            // Adicionar o replay ao estado (isso também remove o mais antigo se necessário)
            commit('addReplay', newReplay);

            // Referência ao novo replay
            const replay = state.replays.find((r:any) => r.id === replayId);
            if (!replay) return;

            // Inicializar objetos do mapa para este replay
            let lineString = new H.geo.LineString();
            let markerGroup = new H.map.Group();

            let positionPoint = new H.map.Icon(icon01);
            let carIcon = new H.map.Icon(icon02, {
                size: { w: 32, h: 62 },
                anchor: { x: 16, y: 66 }
            });

            let counter = 0;

            // Adicionando os pontos de replay
            for (const position of data.positions) {
                lineString.pushPoint({ lat: position.lat, lng: position.lng });
                const marker = new H.map.Marker({ lat: position.lat, lng: position.lng }, { icon: positionPoint });
                replay.data.push(position);
                markerGroup.addObject(marker);

                const carMarker = new H.map.Marker({ lat: position.lat, lng: position.lng }, { icon: carIcon }).setZIndex(10);
                let xy = state.hereMap.geoToScreen({lat: position.lat, lng: position.lng});
                const bubble = new H.ui.InfoBubble(
                    state.hereMap.screenToGeo(xy.x, xy.y - 65)
                );
                bubble.setContent(`
                    <div class="here-infoBubble">
                        <div class="header-infoBubble">
                            ${position.assetDescription}
                        </div>
                        <div class="row">
                            <div class="col">
                                <strong class="subheader-bubble">
                                    Data
                                </strong>
                                <span class="info-bubble">
                                ${moment(position.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                </span>
                            </div>
                            <div class="col">
                                <strong class="subheader-bubble">
                                    Velocidade
                                </strong>
                                <span class="info-bubble">
                                ${position.speed} km/h
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <strong class="subheader-bubble">
                                    Hodômetro
                                </strong>
                                <span class="info-bubble">
                                ${position.odometer}
                                </span>
                            </div>
                        </div>                                    
                        <div class="row">
                            <div class="col">
                                <strong class="subheader-bubble">
                                    Motorista
                                </strong>
                                <span class="info-bubble">
                                ${position.driverName}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="badge-bubble ${position.ignition ? 'success' : 'error'}">
                                ${position.ignition ? 'Ligado' : 'Desligado'}
                                </span>
                            </div>
                        </div>
                    </div>`
                );

                marker.addEventListener('tap', () => {
                    state.hereUi.addBubble(bubble); 
                    replay.infoBubble = bubble;
                }, false);

                if (counter === 0) {
                    carMarker.id = `carMarker_${replayId}`;
                    replay.carMarker = carMarker;
                    state.hereMap.addObject(carMarker);
                }

                counter++;
            }

            // Adiciona a linha do caminho
            let routeLine = new H.map.Polyline(lineString, {
                style: {
                    lineWidth: 8,
                    strokeColor: state.replays.length==1 ? "rgba(118,188,135,1)" 
                    : state.replays.length==2 ? "rgba(41, 128, 185, 1)" 
                    : state.replays.length==3 ? "rgba(142, 68, 173, 1)"
                    : state.replays.length==4 ? "rgba(211, 84, 0, 1)" 
                    : "rgba(192, 57, 43, 1)",
                    lineWeight: 2
                }
            });

            routeLine.id = `positionsPolyline_${replayId}`;
            markerGroup.id = `positionsPoints_${replayId}`;

            routeLine.setVisibility(state.checkboxPolyline);
            markerGroup.setVisibility(state.checkboxTrackingPoint);

            replay.positionPolyline = routeLine;
            replay.trackingPoint = markerGroup;
            state.hereMap.addObject(routeLine);
            state.hereMap.addObject(markerGroup);

            // Centraliza o mapa na rota
            state.hereMap.getViewModel().setLookAtData({
                bounds: routeLine.getBoundingBox(),
                zoom: 10
            }, true);

            // Itinerário
            let itineraryLineString = new H.geo.LineString();
            let itineraryMarkerGroup = new H.map.Group();
            let itineraryPointMarkerGroup = new H.map.Group();
            let stopPointRadiusIcon = new H.map.Icon(icon03, {
                size: { w: 32, h: 32 },
                anchor: { x: 16, y: 16 }
            });

            for (const itinerary of data.itinerary.polyline) {
                itineraryLineString.pushPoint({ lat: itinerary.lat, lng: itinerary.lng });
            }

            for (const itinerary of data.itinerary.points) {
                const itineraryMarker = new H.map.Marker({ lat: itinerary.lat, lng: itinerary.lng }, { icon: stopPointRadiusIcon });
                const itineraryPointMarker = new H.map.Marker({ lat: itinerary.lat, lng: itinerary.lng }, { icon: carIcon });
                itineraryMarkerGroup.addObject(itineraryMarker);
                itineraryPointMarkerGroup.addObject(itineraryPointMarker);
            }

            let itineraryRouteLine = new H.map.Polyline(itineraryLineString, {
                style: {
                    lineWidth: 8,
                    strokeColor: "rgba(0, 0, 0, 1.0)",
                    lineWeight: 2
                }
            });

            itineraryRouteLine.id = `itineraryPolyline_${replayId}`;
            itineraryMarkerGroup.id = `itineraryPoints_${replayId}`;
            itineraryPointMarkerGroup.id = `itineraryStopPoints_${replayId}`;

            itineraryRouteLine.setVisibility(state.checkboxPolyline);
            itineraryMarkerGroup.setVisibility(state.checkboxStopPointRadius);
            itineraryPointMarkerGroup.setVisibility(state.checkboxStopPoints);

            replay.stopPoints = itineraryPointMarkerGroup;
            replay.stopPointRadius = itineraryMarkerGroup;
            replay.itineraryPolyline = itineraryRouteLine;

            state.hereMap.addObject(itineraryPointMarkerGroup);
            state.hereMap.addObject(itineraryRouteLine);
            state.hereMap.addObject(itineraryMarkerGroup);
        },
        initializeMap ({commit, state}: { commit: any; state: any }, {mapContainer , windowH}: { mapContainer: any; windowH: any }){
            commit('setWindowH', toRaw(windowH));

            const herePlatform = new windowH.service.Platform({
                apikey: state.apikey
            });
        
            let maptypes = herePlatform.createDefaultLayers();
            const hereMap = new windowH.Map(toRaw(mapContainer.value), maptypes.vector.normal.map, {
                zoom: 12,
                center: { lat: -7.94752, lng: -34.87756, alt: 0 }
            });
        
            new windowH.mapevents.Behavior(new windowH.mapevents.MapEvents(toRaw(hereMap)));
            
            // Inicializando a UI
            const ui = windowH.ui.UI.createDefault(toRaw(hereMap), maptypes, 'pt-BR');
            
            const scaleBar = ui.getControl('scalebar');
            scaleBar.setVisibility(false);
        
            // Resizer para o mapa
            window.addEventListener("resize", () => toRaw(hereMap).getViewPort().resize());
            state.H = windowH;
            // Salvando no estado o `ui` e o `hereMap`
            state.hereUi = ui;
            state.ui = ui; // Definindo `state.ui` como a interface do mapa
            commit('setMap', hereMap);
            commit('setPlatform', herePlatform);
        },
        toggleFullScreen({commit, state}: { commit: any; state: any }){
            commit('setFullScreen');
        },
        resizeMap({commit, state}: { commit: any; state: any }){
            commit('resizeMap');
        },
     },
    getters: { 
        hereFullScreen(state:  any){
            return state.hereFullScreen;
        },
        checkboxPolyline(state:  any){
            return state.checkboxPolyline;
        },
        checkboxTrackingPoint(state:  any){
            return state.checkboxTrackingPoint;
        },
        checkboxStopPointRadius(state:  any){
            return state.checkboxStopPointRadius;
        },
        checkboxStopPoints(state:  any){
            return state.checkboxStopPoints;
        },
        filterSite(state:any){
            return state.filterSite;
        },        
        filterSiteData(state:any){
            return state.filterSiteData;
        },
        filterAsset(state:any){
            return state.filterAsset;
        },        
        filterAssetData(state:any){
            return state.filterAssetData;
        },
        filterDriver(state:any){
            return state.filterDriver;
        },        
        filterDriverData(state:any){
            return state.filterDriverData;
        },
        filterLine(state:any){
            return state.filterLine;
        },        
        filterLineData(state:any){
            return state.filterLineData;
        },
        tableData(state: any){                                                            
            return state.tableData;
        },
        getLoading(state: any){
            return state.loading;
        },
        getPickedDate(state: any){
            return state.pickedDate
        },
        getReplayStatus(state: any){
            return state.replays.length>0
        },
        getPlayStatus(state: any){
            return state.playReplay
        },
        getTotalPages(state: any){
            return state.totalPages
        },
        getS3Id(state: any){
            return state.s3Id
        },
        getReplaysQuantity(state:any){
            return state.replays.length;
        }

     }
}