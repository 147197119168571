<template>
  <div class="map-options" :class="{'active': isReplayActive}">
    <div class="form-check text-start">
      <input 
        class="form-check-input" 
        type="checkbox" 
        id="checkbox-polyline"
        v-model="checkboxPolyline" 
        :checked="checkboxPolyline">
      <label class="form-check-label" for="checkbox-polyline">
        Polyline
      </label>
    </div>
    <div class="form-check text-start">
      <input 
      class="form-check-input" 
      type="checkbox"
      id="checkbox-tracking"
      v-model="checkboxTrackingPoint" 
      :checked="checkboxTrackingPoint">
      <label class="form-check-label" for="checkbox-tracking">
        Tracking
      </label>
    </div>
    <div class="form-check text-start">
      <input 
      class="form-check-input" 
      type="checkbox"
      id="checkbox-raio"
      v-model="checkboxStopPointRadius" 
      :checked="checkboxStopPointRadius">
      <label class="form-check-label" for="checkbox-raio">
        Raio
      </label>
    </div>
    <div class="form-check text-start">
      <input 
      class="form-check-input" 
      type="checkbox"
      id="checkbox-pontos-de-parada"
      v-model="checkboxStopPoints" 
      :checked="checkboxStopPoints">
      <label class="form-check-label" for="checkbox-pontos-de-parada">
        Pontos de Parada
      </label>
    </div>
  </div>
  <div class="replay-options"  :class="{'active': isReplayActive}">
    <!--<material-icon icon="keyboard_double_arrow_left" @click="playReplay"></material-icon>-->
    <material-icon icon="play_arrow" v-if="!isReplayPlaying" @click="playReplay"></material-icon>
    <material-icon icon="pause" v-else @click="pauseReplay"></material-icon>
    <material-icon icon="stop" @click="stopReplay"></material-icon>
    <!--<material-icon icon="keyboard_double_arrow_right" @click="playReplay"></material-icon>-->
  </div>
</template>
<script setup>
    import { computed } from "vue";
    import { useStore } from 'vuex';
    import {MaterialIcon} from "@mobs2/styleguide";

    const store = useStore();

    const isReplayActive = computed(() => store.getters.getReplayStatus );

    const playReplay = () => {
      store.dispatch('playReplay');
    }

    const pauseReplay = () => {
      store.dispatch('pauseReplay');
    }
    const stopReplay = () => {
      store.dispatch('stopReplay');
    }
    const isReplayPlaying = computed(() => store.getters.getPlayStatus );

    const checkboxPolyline = computed({
      get() {
        return store.getters.checkboxPolyline;
      },
      set(newValue){
        store.dispatch('setCheckboxPolyline', newValue);
      }
    })

    const checkboxTrackingPoint = computed({
      get() {
        return store.getters.checkboxTrackingPoint;
      },
      set(newValue){
        store.dispatch('setCheckboxTrackingPoint', newValue);
      }
    })
    const checkboxStopPointRadius = computed({
      get() {
        return store.getters.checkboxStopPointRadius;
      },
      set(newValue){
        store.dispatch('setCheckboxStopPointRadius', newValue);
      }
    })
    const checkboxStopPoints = computed({
      get() {
        return store.getters.checkboxStopPoints;
      },
      set(newValue){
        store.dispatch('setCheckboxStopPoints', newValue);
      }
    });
</script>
<style scoped>
    .replay-options{
      position: absolute;
      z-index: 100;
      text-align: center;
      width: fit-content;
      padding: .5rem;
      box-sizing: border-box;
      background: #2f2c47;
      box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .25);
      min-width: 134.49px;
      top: 9rem;
      border-radius: .25rem;
      left: -100%;
      transition: left .6s;
    }
    .replay-options span{
      cursor: pointer;
      border-radius: 100%;
      background: #50BF82;
      color: #fff;
    }
    .replay-options span + span{
      margin-left: .25rem
    }
    .map-options{
        position: absolute;
        top: 0.5rem;
        left: -100%;
        z-index: 5;
        padding: .5rem;
        background: #2f2c47;
        border-radius: 0;
        transition: left .6s;
        box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .25);
        border-radius: .25rem;
    }
    .replay-options.active,
    .map-options.active{
      left: .25rem;
    }
    .map-options .form-check{
        color: #fff;
        font-size: .75rem;
    }
    .map-options .form-check label{
        vertical-align: middle;
    }
    .teste{
      position: absolute;
      z-index: 101;
      padding: .5rem 1rem;
      background: red;
      color: #fff;
      top: 0;
      right: 0;
    }
</style>