<template>
    <div class="loader">
        <div class="loader-message">
            <div class="spinner"></div>
            <span>Aguarde, estamos carregando os dados.</span>
        </div>
    </div>
</template>
<style scoped>
.spinner {
   width: 56px;
   height: 56px;
   border-radius: 50%;
   border: 9px solid #50bf82;
   animation: 
         spinner-bulqg1 0.8s infinite linear alternate,
        spinner-oaa3wk 1.6s infinite linear;
   display: inline-block;
   vertical-align: middle;
}
.loader{
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(46, 45, 71, .85);
    
}
.loader-message {
    position: absolute;
    width: fit-content;
    bottom: 1rem;
    left: 1rem;
}
.loader-message span[data-v-04a0d67a] {
    display: inline-block;
    margin-left: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
}
@media screen and (max-width: 991px){
   .loader{
      text-align: center;
   }
   .spinner{
      margin-bottom: 1rem;
   }
}
@keyframes spinner-bulqg1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      transform: scaleY(1) rotate(135deg);
   }

   50% {
      transform: scaleY(-1) rotate(0deg);
   }

   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}
</style>
