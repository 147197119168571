<template>
    <PerfectScrollbar class="m2-app container-fluid" style="max-height: 100vh;" v-if="isVisible">
        <div class="row">
            <Filter></Filter>
        </div>
        <div class="row">
            <div class="col-md-12 mb-2" 
                :class="{'col-xl-4 col-lg-4': hereFullScreen, 'col-xl-8 col-lg-9': !hereFullScreen}">
                <sgf-points-passage-table></sgf-points-passage-table>
            </div>
            <div class="col-md-12 mb-2"
            :class="{'col-xl-8 col-lg-8': hereFullScreen, 'col-xl-4 col-lg-3': !hereFullScreen}">
                <here-map></here-map>
            </div>
        </div>
        <Transition>
            <loader v-if="pageLoading"></loader>
        </Transition>
        <Transition>
            <friendly-notification
            :options="notification"
            v-if="notification.enabled"
            ></friendly-notification>
        </Transition>
    </PerfectScrollbar>
</template>
<script setup>

    import 'vue3-perfect-scrollbar/style.css';
    import {default as HereMap} from '@/components/HereMap'
    import {default as EasterEgg} from '@/components/EasterEgg'
    import {default as SgfPointsPassageTable} from '@/components/SgfPointsPassageTable'
    import {default as Filter} from '@/components/Filter.vue'
    import {default as Loader} from '@/components/Loader.vue'
    import { FriendlyNotification } from '@mobs2/styleguide'
    import { computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate, onDeactivated, onErrorCaptured, onMounted, onRenderTracked, onRenderTriggered, onServerPrefetch, onUnmounted, onUpdated } from 'vue';
    import { useStore } from 'vuex';
    const store = useStore();
    const hereFullScreen = computed(() => store.getters.hereFullScreen)
    const pageLoading = computed (() => store.getters.getPageLoading)
    const notification = computed (() => store.getters.getNotification)

    import { useRoute, useRouter  } from 'vue-router'

    const isVisible = computed(() => (sessionStorage.getItem('orgid') && sessionStorage.getItem('userid')))

    const route = useRoute()
    const router = useRouter()

    const orgid = computed(() => route.params.orgid);
    const userid = computed(() => route.params.userid);

    router.isReady().then(() => {
        store.dispatch('checkCredentials', {
            orgid: route.params.orgid,
            userid: route.params.userid,
        }).then((response) =>{
            if(response.doRedirect){
                if(response.path === 'samePage'){
                    router.replace({name: route.name, params: {}, query: {}});
                    if(route.params.orgid != '' && route.params.userid != ''){
                        setTimeout(() => {
                            router.go();
                        }, 250)
                    }
                }else{
                    window.location.replace(response.path)
                }
            }
            //store.dispatch('toggleItemStatus','loadingStatus')
        });
    }); 
</script>