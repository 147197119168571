import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Main from "@/views/OperationalMonitoring/Main.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/sgf/operational-monitoring/:orgid?/:userid?",
    name: "operational-monitoring",
    component: Main
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
