<template>
    <div class="table-responsive">
        <table class="table">
        <thead>
            <tr>
                <th>&nbsp;</th>
                <th>Ações</th>
                <th>Data</th>
                <th>Ativo</th>
                <th>Motorista</th>
                <th :class="{ 'displayNone': hereFullScreen}">Saída de Garagem</th>
                <th :class="{ 'displayNone': hereFullScreen}">Linha</th>
                <th :class="{ 'displayNone': hereFullScreen}">Viagem</th>
                <th :class="{ 'displayNone': hereFullScreen}">&nbsp;</th>
                <th :class="{ 'displayNone': hereFullScreen}">Container</th>
                <th :class="{ 'displayNone': hereFullScreen}">Aterro</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item, index in tableData.data" v-if="tableData.data" :key="index">
                <td>
                    <font-awesome-icon 
                        v-if="item.replayIndex"
                        icon="circle" 
                        class="font-awesome-icon"
                        :style="{cursor: 'pointer', color: item.replayIndex ? replayColors[item.replayIndex-1] : 'transparent'}"
                        @click="removeReplay(item)"
                    ></font-awesome-icon>
                </td>
                <td style="white-space: nowrap;" >
                    <font-awesome-icon 
                        icon="rotate" 
                        class="font-awesome-icon"
                        style="cursor: pointer"
                        @click="getReplay(item.assetId, item.startDatetime, item.endDatetime, item.itineraryId, item)"
                    ></font-awesome-icon>
                    <font-awesome-icon 
                        icon="file-excel" 
                        class="font-awesome-icon"
                        style="cursor: pointer"
                        @click="exportExcel(index)"
                    ></font-awesome-icon>
                    <font-awesome-icon 
                        icon="file-pdf" 
                        class="font-awesome-icon"
                        style="cursor: pointer"
                        @click="exportPDF(index)"
                    ></font-awesome-icon>
                </td>
                <td>{{  formatDate(item.date) }}</td>
                <td>{{  item.assetRegistrationNumber }}</td>
                <td>{{  item.driverEmployeeNumber }}</td>
                <td :class="{ 'displayNone': hereFullScreen}">
                    {{  
                        garageInfo(item.garagePointName, item.garagePointTimestamp)
                    }}
                </td>
                <td :class="{ 'displayNone': hereFullScreen}">
                    {{  item.routeName }}
                </td>
                <td :class="{ 'displayNone': hereFullScreen}">
                    {{  formatDateTime(item.firstPointTimestamp) }}
                </td>
                <td style="min-width: 100px" :class="{ 'displayNone': hereFullScreen}">
                    <status-bar 
                        :statusBarClass="'success'" 
                        :statusBarValue="item.percentageOfItineraryPointsRealized">
                    </status-bar>
                </td>
                <td :class="{ 'displayNone': hereFullScreen}">{{  item.numberOfContainerPoints }}</td>
                <td :class="{ 'displayNone': hereFullScreen}">
                    {{  
                        landfillInfo(item.landfillPointName, item.landfillPointTimestamp)
                    }}
                </td>
            </tr>            
            <tr v-else>
                <td colspan="11" class="text-center">
                    <h3 class="mt-3 mb-3">
                        Preencha os filtros e clique em adicionar
                    </h3>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
    <pagination v-model="actualPage" :records="tableData.totalPages" :per-page="15" @paginate="myCallback"/>

</template>

<script setup>
    import Pagination from 'v-pagination-3';

    import { StatusBar, FontAwesomeIcon } from "@mobs2/styleguide";
    import { computed, ref } from "vue";
    import { useStore } from 'vuex';
    import moment from "moment";
    import axios from "axios";

    const store = useStore();

    const replayColors = ref(["rgba(118,188,135,1)","rgba(41, 128, 185, 1)","rgba(142, 68, 173, 1)","rgba(211, 84, 0, 1)","rgba(192, 57, 43, 1)"])

    const tableData = computed(() => store.getters.getTableData);

    const actualPage = ref(1)
    const hereFullScreen = computed(() => store.getters.hereFullScreen)



    const myCallback = async (evt) => {
        store.commit('TOGGLE_STATE_ITEM', 'pageLoading');
        await axios.get(`https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/operational-registers-report/${tableData.value.s3Id}/${evt}`,{
            headers: {
                organization: sessionStorage.getItem('orgid')
            }
        }).then(response => {
            store.commit('SET_TABLE_DATA2', response.data.data);
        }).catch(error => {
            store.dispatch('setNotification', {
                enable: true,
                type: 'error',
                text: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
            });
        });
        store.commit('TOGGLE_STATE_ITEM', 'pageLoading');
    }

    const formatDate = (value) => {

        return value ? moment(value).format("DD/MM/YYYY") : '';
    };

    const formatDateTime = (value) => {
        return value ? moment(value).subtract(3, 'h').format("DD/MM/YYYY HH:mm:ss") : '';
    };

    const garageInfo = (name, time) => {
        if(name && time){
            let formattedTime = formatDateTime(time);
            return `${name} | ${formattedTime}`;
        }else if(name){
            return `${name}`;
        }else if(time){
            let formattedTime = formatDateTime(time);
            return `${formattedTime}`;
        }else{
            return ' - ';
        }
    };

    const exportExcel = async (index) =>{
        store.dispatch('exportTableData', {type: 'xlsx', index: index});
    }

    const exportPDF = async (index) =>{
        store.dispatch('exportTableData', {type: 'pdf', index: index});
    }

    const getReplay = async (asset, start, end, itinerary, item) =>{
        if(store.getters.getReplaysQuantity==5){
            store.dispatch('setNotification', {
                enable: true,
                type: 'error',
                text: 'A quantidade máxima (5) de replays foi atingida. Remova algum para adicionar.',
            });
        }else{

            store.commit('TOGGLE_STATE_ITEM', 'pageLoading');
            await axios.get('https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/replay',
            {
                headers: {
                    organization: sessionStorage.getItem('orgid')
                },
                params: {
                    assetid: asset,
                    startdatetime: moment(start).subtract(3, 'h').format('YYYY-MM-DD HH:mm:ss'),
                    enddatetime:  moment(end).subtract(3, 'h').format('YYYY-MM-DD HH:mm:ss'),
                    itineraryid: itinerary,
                }
            }).then(response => {
                store.dispatch('setReplayData', { 
                    data: response.data.data, 
                    H: window.H, 
                    icon01: require('@/assets/imgs/ponto_posicionamento.png'),
                    icon02: require('@/assets/imgs/vehicle.svg'),
                    icon03: require('@/assets/imgs/ponto_parada.png')
                });
                item.replayIndex = store.getters.getReplaysQuantity;
            });
            store.commit('TOGGLE_STATE_ITEM', 'pageLoading');
        }
    }

    const landfillInfo = (name, time) => {
        if(name && time){
            let formattedTime = formatDateTime(time);
            return `${name} | ${formattedTime}`;
        }else if(name){
            return `${name}`;
        }else if(time){
            let formattedTime = formatDateTime(time);
            return `${formattedTime}`;
        }else{
            return ' - ';
        }
    };

    const removeReplay = async (item) => {
        store.commit('removeReplay', item.replayIndex);
        item.replayIndex = null;
    }
</script>
<style scoped>
    .table-responsive{
        height: 80vh;   
        overflow: scroll;
        scrollbar-color: #77bb89 transparent;
        scrollbar-width: thin;
    }
    
    table,
    table thead,
    table tbody,
    table thead tr, 
    table thead tr th,
    table tbody tr, 
    table tbody tr td{
        border: none;
        font-size: 0.875rem;
        color: #fff;
    }
    
    table thead tr th{
        text-align: center;
        font-weight: 700;
    }

    table tbody tr td{
        text-align: center;
        font-weight: 400;
        font-size: 0.75rem;
    }
    
    .font-awesome-icon{
        font-size: 1rem;
        opacity: .75;
        transition: opacity .3s;
    }
    .font-awesome-icon:hover{
        opacity: 1;
    }
    .font-awesome-icon + .font-awesome-icon{
        margin-left: .25rem
    }
</style>
