import axiosInstance from "@/axiosConfig";
import { notificationMessages } from "@/data";
import { ActionContext } from "vuex";
import moment from "moment";

interface TableState {
    tableData: {
        data: any[],
        s3Id: string,
        totalPages: number
    };
}

export const tableStore = {
    state: ()=> ({
        tableData: {
            data: null,
            s3Id: null,
            totalPages: 0          
        },
    }),
    mutations: {
        SET_TABLE_DATA(state: TableState, data: any){
            state.tableData = {
                data: data.firstPage,
                s3Id: data.id,
                totalPages: data.totalPages
            };
        },
        SET_TABLE_DATA2(state: TableState, data: any){
            state.tableData = {
                data: data.row,
                s3Id: state.tableData.s3Id,
                totalPages: state.tableData.totalPages
            };
        },
    },
    actions: { 
        async setTableData({ commit, rootState }: ActionContext<TableState, any>){
            commit('TOGGLE_STATE_ITEM', 'pageLoading');
            let stateFilters = rootState.config.filterItens;
            await axiosInstance.get('operational-registers-report/paginated/',
            {
                params: {
                    startdate: stateFilters.date.data[0],
                    enddate: stateFilters.date.data[0],
                    assetid:  stateFilters.assets.value,
                    driverid: stateFilters.drivers.value,
                    garageid: stateFilters.garages.value,
                    routeid: stateFilters.routes.value,
                }
            }).then(response => {
                commit('SET_TABLE_DATA', response.data.data);
            }).catch(response => {
                commit('TOGGLE_SET_NOTIFICATION', notificationMessages.axiosError);
            });
            await commit('TOGGLE_STATE_ITEM', 'pageLoading');
        },
        async exportTableData({ commit, rootState, state }: ActionContext<TableState, any>, {type, index}:{type: string, index: number}){
            commit('TOGGLE_STATE_ITEM', 'pageLoading');
            let tableData = state.tableData.data[index];
            await axiosInstance.post('export-trackpoints-report ',
                {
                        orgId: sessionStorage.getItem('orgid'),
                        userId: sessionStorage.getItem('userid'),
                        reportType: type,
                        assetId: tableData.assetId,
                        driverId: tableData.driverId,
                        itineraryId:  tableData.itineraryId,
                        startdatetime: moment( tableData.startDatetime).subtract(3, 'h'),
                        enddatetime: moment( tableData.endDatetime).subtract(3, 'h'),
                }).then(() => {
                    commit('TOGGLE_SET_NOTIFICATION', {
                        enabled: true,
                        type: 'success',
                        text: 'Informações enviadas por email.',
                    });
                }).catch(() => {
                    commit('TOGGLE_SET_NOTIFICATION', {
                        enabled: true,
                        type: 'error',
                        text: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
                    });
                });
            commit('TOGGLE_STATE_ITEM', 'pageLoading');
        }
    },
    getters: { 
        getTableData(state: TableState){
            return state.tableData;
        },
    }
}