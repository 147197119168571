import { h, createApp } from "vue";
import singleSpaVue from "single-spa-vue";
import router from "./router/index";
import App from "./App.vue";
import store from "./store";

import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';

const vueLifecycles = singleSpaVue({
  createApp: (...args) => {    
      const vueInstance = createApp(...args);
      vueInstance.use(router).use(store).use(PerfectScrollbarPlugin);
      return vueInstance;    
  },
  appOptions: {
    render() {
      return h(App, {});
    },
  },
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
