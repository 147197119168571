
<template>
  <div id="map">
    <here-map-options />
    <div id="mapContainer" style="height:100vh;width:100%; bottom: 0; position: absolute;" ref="hereMap"></div>
    <material-icon icon="fullscreen" @click="toggleResize"></material-icon>
  </div>
</template>
<script setup>
  import { ref, onMounted, computed, nextTick } from "vue";
  import { useStore } from 'vuex';
  import {MaterialIcon} from "@mobs2/styleguide";
  import { default as HereMapOptions } from "./HereMapOptions.vue";
  const hereMap = ref(null);
  const hereFullScreen = computed(() => store.getters.hereFullScreen)
  const store = useStore();
  const toggleResize = async () => {
    store.dispatch('toggleFullScreen');
    await nextTick();
    store.dispatch('resizeMap');
  }

  onMounted(() => {
    if (hereMap) {
      store.dispatch('initializeMap', {
        mapContainer: hereMap,
        windowH: window.H
      });
    }
  })
</script>

<style scoped>
  #map {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    text-align: center;
    background-color: #ccc;
    transition: width .3s, height .3s;
  }

  #map.fullscreen-map{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
  }
  #map>span{
    position: absolute;
    bottom: .25rem;
    left: .25rem;
    background: #77bb89;
    color: #fff;
    font-size: 3rem;
  }
  .H_l_vertical .H_zoom .H_el {
    display: inline-block;
    margin-bottom: 0;
  }
  .H_ctl.H_el.H_zoom.H_grp{
    background: transparent;
    box-shadow: none;
  }
  </style>
  
