import axiosInstance from "@/axiosConfig";

import { FilterOptions, Filters, Notification } from "@/interfaces/filters";
import { filterItensConfig, notificationMessages } from "@/data";
import { ActionContext } from "vuex";

interface ConfigState {
    filterOptions: FilterOptions;
    filterButtonStatus: Boolean;
    filterItens: Filters;
    notification: Notification;
    pageLoading: Boolean;
    tableData: any;
    orgId: any;
    userId: any;
}

export const configStore = {
    state: ()=> ({
        apikey: "ni-Vh7ZV9Y5SIPYhFj01H3B6NT4oKH05kofRQgxq6dU",
        filterOptions:{
            columnSizing: ['col-xl col-lg-6 col-md-12 mb-2'],
            responsive: true
        },
        filterButtonStatus: false,
        filterItens: filterItensConfig,
        notification:{
            enabled: false,
            text: null,
            type: null,
        },
        pageLoading: false,
        orgId: null,
        userId: null
    }),
    mutations: {
        SET_FILTER_DATA(state: ConfigState, {filter, data} : {filter: keyof typeof state.filterItens, data: Object[]}){
            state.filterItens[filter].data = data;
        },
        TOGGLE_FILTER_STATUS(state: ConfigState, filter: keyof typeof state.filterItens){ 
            state.filterItens[filter].disabled = !state.filterItens[filter].disabled;
        },
        TOGGLE_FILTER_LOADING(state: ConfigState, filter: keyof typeof state.filterItens){
            state.filterItens[filter].loading = !state.filterItens[filter].loading; 
        },
        TOGGLE_SET_NOTIFICATION(state: ConfigState, notification: Notification){
            state.notification = notification;
            setTimeout(() => {
                state.notification = {
                    enabled: false,
                    text: undefined,
                    type: undefined,
                };
            }, 3000);
        },
        TOGGLE_STATE_ITEM(state: ConfigState, item: keyof typeof state){
            state[item] = !state[item]
        },
    },
    actions: { 
        setFilterDate({ commit, dispatch, state }: ActionContext<ConfigState, any>, data: Date[]){
            commit('SET_FILTER_DATA', {filter: 'date', data: data});
            commit('TOGGLE_STATE_ITEM', 'filterButtonStatus')
            Object.keys(state.filterItens).forEach((key) => {
                if(key !== 'date'){
                    dispatch('getFilterData', key.toString());
                }
            })
        },
        async getFilterData({ commit }: ActionContext<ConfigState, any>, filter: string){
            commit('TOGGLE_FILTER_LOADING', filter);
            await axiosInstance.get(filter).then(response => {
                commit('SET_FILTER_DATA', {filter: filter, data: response.data.data[filter]})
                commit('TOGGLE_FILTER_STATUS', filter);
                
            }).catch(() => {
                commit('TOGGLE_SET_NOTIFICATION', notificationMessages.axiosError)
            });
            commit('TOGGLE_FILTER_LOADING', filter);
        },
        setNotification({ commit }: ActionContext<ConfigState, any>, notification: Notification){
            commit('TOGGLE_SET_NOTIFICATION', notification)
        },
        checkCredentials({state}: ActionContext<ConfigState, any>, {orgid, userid}: {orgid: string, userid: string}){
            return new Promise(async resolve => {
                let redirect = {
                    doRedirect: false,
                    path: ''
                };

                if(orgid && userid){
                    let isValidSession = (state.orgId === orgid && state.userId === userid);
                    if(!isValidSession){
                        sessionStorage.setItem('orgid', orgid);
                        sessionStorage.setItem('userid', userid);   
                    }
                    redirect = {
                        doRedirect: true,
                        path: 'samePage'
                    }
                }

                state.orgId = sessionStorage.getItem('orgid')
                state.userId = sessionStorage.getItem('userid')

                resolve(redirect);
            })            
        },
    },
    getters: { 
        getFilterItens(state: ConfigState){
            return state.filterItens;
        },
        getFilterOptions(state: ConfigState){
            return state.filterOptions;
        },
        isFilterButtonActive(state: ConfigState){
            return state.filterButtonStatus;
        },
        getNotification(state: ConfigState){
            return state.notification;
        },
        getPageLoading(state: ConfigState){
            return state.pageLoading;
        },
    }
}