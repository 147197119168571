import { createStore, Store } from "vuex";
import { hereMapsStore } from "./here/index";
import { InjectionKey } from "vue";
import { configStore } from "./modules/config";
import { tableStore } from "./modules/table";

export interface State{
  map: any
}

export const key: InjectionKey<Store<State>> = Symbol()

export default createStore({
  modules: {
    hereMap: hereMapsStore,
    config: configStore,
    table: tableStore,
  }
});