import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://quf052tc0m.execute-api.us-east-1.amazonaws.com/dev/',
    timeout: 30000,
    headers: {
        'Organization': sessionStorage.getItem('orgid')
    }
});

export default axiosInstance;